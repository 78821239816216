import { datoFetch } from '../../services/datocms/fetch';
import Navbar from '../../components/navbar/Navbar';
import styles from './404.module.scss';
import Text from '../../components/display/Text';
import Link from 'next/link';




const QUERY = `query NotFoundPage {
  notfoundPage {
    mainTitle
    subtitle
    blurb
    goHomeButtonText
	}
}`;

export async function getStaticProps(context: { preview?: boolean }) {
  const data: { notfoundPage: NotfoundPage } = await datoFetch({ query: QUERY, preview: context.preview });
  if (!data?.notfoundPage) {
    // TODO - Log errors
    throw new Error('Failed to fetch new data, serving cached content');
  }
  // const categories = data.aboutUsPage.categoryList.map(category => category.categoryTitle);

  return {
    props: {
      content: data.notfoundPage,
    },
    revalidate: 60 * 60, // At most once per hour
  };
}


interface NotfoundPage {
  mainTitle: string;
  blurb:string;
  subtitle:string;
  goHomeButtonText:string
}
function notfoundPage(props: { content: NotfoundPage }): React.ReactElement {
    const { content } = props;



  return (
      <div className={styles.container}>
          <Navbar/>
        <div className={styles.titleContainer}>
<div className={styles.titleBlock}>
          <div className={styles.mainTitle}>{content.mainTitle}</div>
          <Text textAlign='center' size='h2' type='dark' title={content.subtitle}  />
          <Text textAlign='center' size='paragraph1' type='dark' title={content.blurb} paddingTop='27px' />
          <Link href="/" passHref>
                <button type='submit' className={styles.submitBtn}>
                  <div className={styles.btnContent}>
                    {content.goHomeButtonText}
                  </div>
                </button>
              </Link>
          </div>
                    </div>
      </div>
  );
}

export default notfoundPage;